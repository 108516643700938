import './dropdown.css'
import DropdownItem from './DropdownItem'
const Dropdown = ({
   items,
   allowedItems,
   selectedItems,
   setSelectedItems,
   isCheckedAllItems,
   setIsCheckedAllItems,
   isShowItemDropdown,
   setIsShowItemDropdown,
}) => {
   const toggleShowDropdown = () => {
      setIsShowItemDropdown(!isShowItemDropdown)
   }

   const addItemToMailing = (item) => {
      setSelectedItems((prevState) => {
         return [...prevState, item]
      })
   }

   const removeItemFromMailing = (item) => {
      setSelectedItems((prevState) => {
         return prevState.filter((element) => element.id !== item.id)
      })
   }

   return (
      <div className="dropdown">
         <div className="dropdown-wrapper">
            <div className="dropdown-current-item" onClick={toggleShowDropdown}>
               <span className="dropdown-current-item_name">
                  Только выбранные
               </span>
               <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  {isShowItemDropdown ? (
                     <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.2197 8.21967C11.5126 7.92678 11.9874 7.92678 12.2803 8.21967L18.2803 14.2197C18.5732 14.5126 18.5732 14.9874 18.2803 15.2803C17.9874 15.5732 17.5126 15.5732 17.2197 15.2803L11.75 9.81066L6.28033 15.2803C5.98744 15.5732 5.51256 15.5732 5.21967 15.2803C4.92678 14.9874 4.92678 14.5126 5.21967 14.2197L11.2197 8.21967Z"
                        fill="#252525"
                     />
                  ) : (
                     <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.21967 8.21967C5.51256 7.92678 5.98744 7.92678 6.28033 8.21967L11.75 13.6893L17.2197 8.21967C17.5126 7.92678 17.9874 7.92678 18.2803 8.21967C18.5732 8.51256 18.5732 8.98744 18.2803 9.28033L12.2803 15.2803C11.9874 15.5732 11.5126 15.5732 11.2197 15.2803L5.21967 9.28033C4.92678 8.98744 4.92678 8.51256 5.21967 8.21967Z"
                        fill="#252525"
                     />
                  )}
               </svg>
            </div>
            {isShowItemDropdown && (
               <div className="dropdown-list">
                  <div className="dropdown-list_wrapper">
                     {selectedItems.length > 0 ? (
                        <ul className="dropdown-list_selected-items-block">
                           {selectedItems.map((item) => {
                              return (
                                 <li key={item.id} className="selected-item">
                                    <div className="selected-item_block">
                                       <span className="selected-item_name">
                                          {item.name ||
                                             `${item.type}-${item.username}`}
                                       </span>
                                       <svg
                                          onClick={() =>
                                             removeItemFromMailing(item)
                                          }
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                       >
                                          <path
                                             fill-rule="evenodd"
                                             clip-rule="evenodd"
                                             d="M4.31295 4.31295C4.50821 4.11769 4.8248 4.11769 5.02006 4.31295L11.6867 10.9796C11.882 11.1749 11.882 11.4915 11.6867 11.6867C11.4915 11.882 11.1749 11.882 10.9796 11.6867L4.31295 5.02006C4.11769 4.8248 4.11769 4.50821 4.31295 4.31295Z"
                                             fill="white"
                                          />
                                          <path
                                             fill-rule="evenodd"
                                             clip-rule="evenodd"
                                             d="M11.6867 4.31295C11.882 4.50821 11.882 4.8248 11.6867 5.02006L5.02006 11.6867C4.8248 11.882 4.50821 11.882 4.31295 11.6867C4.11769 11.4915 4.11769 11.1749 4.31295 10.9796L10.9796 4.31295C11.1749 4.11769 11.4915 4.11769 11.6867 4.31295Z"
                                             fill="white"
                                          />
                                       </svg>
                                    </div>
                                 </li>
                              )
                           })}
                        </ul>
                     ) : (
                        <></>
                     )}

                     <hr className="dropdown-list-border"></hr>
                     <ul className="dropdown-list_items">
                        {items.map((item) => {
                           return (
                              <DropdownItem
                                 setIsCheckedAllItems={setIsCheckedAllItems}
                                 isCheckedAllItems={isCheckedAllItems}
                                 key={item.id}
                                 items={items}
                                 item={item}
                                 selectedItems={selectedItems}
                                 addItemToMailing={addItemToMailing}
                                 removeItemFromMailin={removeItemFromMailing}
                              />
                           )
                        })}
                     </ul>
                  </div>
                  <button
                     className="dropdown-list-accept-button"
                     onClick={() => setIsShowItemDropdown(false)}
                  >
                     Применить
                  </button>
               </div>
            )}
         </div>
      </div>
   )
}

export default Dropdown
