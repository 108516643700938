import React from 'react'
import './Button.css'

import { btnIcon, btnIconStyles, btnSizes, btnSizeStyles, btnThemes, btnThemeStyles } from './consts'

const Button = ({ 
   type = 'button',
   theme = btnThemes.PRIMARY,
   size = btnSizes.DEFAULT,
   icon = btnIcon.NONE,
   customStyle = '',
   text = '',
   disabled,
   onClick = () => {},
   children
}) => {
   const themeStyle = btnThemeStyles[theme]
   const sizeStyle = btnSizeStyles[size]
   const iconStyle = btnIconStyles[icon]
   const withIcon = icon !== btnIcon.NONE   

   return (
      <button
         type={type}
         className={`btn ${themeStyle} ${sizeStyle} ${iconStyle} ${customStyle}`}
         disabled={disabled}
         onClick={onClick}
      >
         {text}
         {withIcon && (
            <svg className='btn__icon' width='11px' viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fill-rule="evenodd" clip-rule="evenodd" d="M4.47994 0.478478C4.67521 0.283216 4.99179 0.283216 5.18705 0.478478L9.18705 4.47848C9.38231 4.67374 9.38231 4.99032 9.18705 5.18558C8.99179 5.38085 8.67521 5.38085 8.47994 5.18558L4.8335 1.53914L1.18705 5.18558C0.991787 5.38085 0.675205 5.38085 0.479943 5.18558C0.284681 4.99032 0.284681 4.67374 0.479943 4.47848L4.47994 0.478478Z" fill="#252525"/>
            </svg>
         )}
         {children}
      </button>
   )
}

export default Button